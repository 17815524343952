/* Temporarily moved to config/ folder, but we are discussing a new way of storing app configurations for the front-end. */
/**
 * Job details related data- used for matching translations.
 *
 * Usage:
 * let jobCompensation = compensation[compensationTypeId] || "";
 * intl.formatMessage({id: `Job.Compensation.${jobCompensation}`})
 */

export const compensation = {
  1: "PerHour",
  2: "PerDay",
  3: "PerWeek",
  4: "PerMonth",
  5: "PerYear",
  6: "4Weekly"
};

// DOVA currency mapping.
export const currency = {
  '1': '£',
  '2': '$',
  '3': '€',
  '4': 'AU$',
  '5': '$',
  '6': 'NZ$',
  '7': 'Rs',
  '8': 'DH',
  '9': '$',
  '10': 'QR',
  '11': 'SR',
  '12': 'MTn',
  '13': '$',
  '14': '$',
  '15': '$',
  '16': '$',
  '17': '¥',
  '18': 'Fr',
  '19': 'CNY',
  '20': 'kr.',
  '21': 'SEK',
  '22': 'S$',
  '23': 'kr',
  '24': 'HK$',
  '25': 'KWD',
  '27': 'RM',
  '28': 'R$',
  '29': 'TRY',
  '30': 'BD',
  '31': 'OMR',
  '32': 'Kc',
  '33': 'kr.',
  '34': 'E£',
  '35': 'Rs.',
  '36': '?',
  '37': '?',
  '38': 'Br',
  '39': 'GH?',
  '40': 'KSh',
  '41': 'MK',
  '42': 'K',
  '43': 'NPR',
  '44': 'K',
  '45': 'PHP',
  '46': 'FRw',
  '47': 'Le',
  '48': 'R',
  '49': 'TSh',
  '50': 'THB',
  '51': 'USh',
  '52': 'ZK',
  '53': 'Ft',
  '54': 'PLN',
  '55': '?',
  '56': '?',
};


export const jobTypes = {
  1: "Permanent",
  2: "Temporary",
  3: "Contract",
  4: "TempToPerm",
  5: "Interim",
  6: "Voluntary",
  7: "Training",
  8: "Internship",
  9: "WorkFromHomeContract",
  10: "WorkFromHomePermanent"
};