module.exports = {
    "country-list": {
        "url": `${process.env.REACT_APP_API_PREFIX}/country-list`,
        "callback": "api/country-list"
    },
    "signup": {
        "url": `${process.env.REACT_APP_API_PREFIX}/signup`,
        "callback": "api/signup/at"
    }
};
