// Get forms schema which need to be included.
const formsCore = require('./forms.config.json');
let formsOpCo = {};
try {
  formsOpCo = require(`./${process.env.REACT_APP_COUNTRY}/forms.config.json`);
}
catch (e) {}

function getSchemas() {
  return {
    ...formsCore,
    ...formsOpCo
  }
}
export default getSchemas;
