module.exports = {
  "confirm-signup": {
    "url": `${process.env.REACT_APP_API_PREFIX}/confirm-signup`,
    "callback": "api/confirm-signup"
  },
  "confirm-signup-link-callback": {
    "url": `${process.env.REACT_APP_API_PREFIX}/confirm-signup-link-callback`,
    "callback": "api/confirm-signup-link-callback"
  },
  "job-alerts-send-unsubscribe-link": {
    "url": `${process.env.REACT_APP_API_PREFIX}/job-alerts/send-unsubscribe-link`,
    "callback": "api/job-alerts-send-unsubscribe-link"
  },
  "job-alerts-unsubscribe": {
    "url": `${process.env.REACT_APP_API_PREFIX}/job-alerts-unsubscribe`,
    "callback": "api/job-alerts-unsubscribe"
  },
  "job-alerts": {
    "url": `${process.env.REACT_APP_API_PREFIX}/job-alerts/?*`,
    "callback": "api/job-alerts"
  },
  "user-ats-id": {
    "url": `${process.env.REACT_APP_API_PREFIX}/user/atsCandidateId`,
    "callback": "api/user-ats-id",
    "access": "authenticated"
  },
  "user-education-details": {
    "url": `${process.env.REACT_APP_API_PREFIX}/user/education-details`,
    "callback": "api/user-education-details",
    "access": "authenticated"
  },
  "user-contact-details": {
    "url": `${process.env.REACT_APP_API_PREFIX}/user/contact-details`,
    "callback": "api/user-contact-details",
    "access": "authenticated"
  },
  "user-job-alerts": {
    "url": `${process.env.REACT_APP_API_PREFIX}/user/job-alerts/*`,
    "callback": "api/user-job-alerts",
    "access": "authenticated"
  },
  "user-job-industry": {
    "url": `${process.env.REACT_APP_API_PREFIX}/user/job-industry`,
    "callback": "api/user-job-industry",
    "access": "authenticated"
  },
  "user-work": {
    "url": `${process.env.REACT_APP_API_PREFIX}/user/work`,
    "callback": "api/user-work",
    "access": "authenticated"
  },
  "user-location": {
    "url": `${process.env.REACT_APP_API_PREFIX}/user/location`,
    "callback": "api/user-location",
    "access": "authenticated"
  },
  "user-commute": {
    "url": `${process.env.REACT_APP_API_PREFIX}/user/commute`,
    "callback": "api/user-commute",
    "access": "authenticated"
  },
  "user-contact-preferences": {
    "url": `${process.env.REACT_APP_API_PREFIX}/user/contact-preferences`,
    "callback": "api/user-contact-preferences",
    "access": "authenticated"
  },
  "user-job-applications": {
    "url": `${process.env.REACT_APP_API_PREFIX}/user/job-applications`,
    "callback": "api/user-job-applications",
    "access": "authenticated"
  },
  "user-open-applications": {
    "url": `${process.env.REACT_APP_API_PREFIX}/user/open-applications`,
    "callback": "api/user-open-applications",
    "access": "authenticated"
  },
  "user-optional-consent": {
    "url": `${process.env.REACT_APP_API_PREFIX}/user/optional-consent`,
    "callback": "api/user-optional-consent",
    "access": "authenticated"
  },
  "user-personal-details": {
    "url": `${process.env.REACT_APP_API_PREFIX}/user/personal-details`,
    "callback": "api/user-personal-details",
    "access": "authenticated"
  },
  "user-name-details": {
    "url": `${process.env.REACT_APP_API_PREFIX}/user/name-details`,
    "callback": "api/user-name-details",
    "access": "authenticated"
  },
  "user-address-details": {
    "url": `${process.env.REACT_APP_API_PREFIX}/user/address-details`,
    "callback": "api/user-address-details",
    "access": "authenticated"
  },
  "user-resume": {
    "url": `${process.env.REACT_APP_API_PREFIX}/user/resume`,
    "callback": "api/user-resume",
    "access": "authenticated"
  },
  "user": {
    "url": `${process.env.REACT_APP_API_PREFIX}/user`,
    "callback": "api/user",
    "access": "authenticated"
  },
  "user-data": {
    "url": `${process.env.REACT_APP_API_PREFIX}/user-data`,
    "callback": "api/user-data",
    "access": "authenticated"
  },
  "login": {
    "url": `${process.env.REACT_APP_API_PREFIX}/login`,
    "callback": "api/login"
  },
  "qualifications": {
    "url": `${process.env.REACT_APP_API_PREFIX}/user/qualifications/*`,
    "callback": "api/qualifications",
    "access": "authenticated"
  },
  "saved-jobs": {
    "url": `${process.env.REACT_APP_API_PREFIX}/user/saved-jobs/*`,
    "callback": "api/saved-jobs"
  },
  "signup": {
    "url": `${process.env.REACT_APP_API_PREFIX}/signup`,
    "callback": "api/signup"
  },
  "skills": {
    "url": `${process.env.REACT_APP_API_PREFIX}/user/skills/*`,
    "callback": "api/skills",
    "access": "authenticated"
  },
  "languages": {
    "url": `${process.env.REACT_APP_API_PREFIX}/user/languages/*`,
    "callback": "api/languages",
    "access": "authenticated"
  },
  "work-xp": {
    "url": `${process.env.REACT_APP_API_PREFIX}/user/work-xp/*`,
    "callback": "api/work-xp",
    "access": "authenticated"
  },
  "refresh-token": {
    "url": `${process.env.REACT_APP_API_PREFIX}/refresh-token`,
    "callback": "api/refresh-token"
  },
  "refresh-token-client": {
    "url": "/my-randstad/refresh-token/",
    "callback": "api/refresh-token"
  },
  "validate-signup": {
    "url": `${process.env.REACT_APP_API_PREFIX}/validate-signup`,
    "callback": "api/validate-signup"
  },
  "change-password": {
    "url": `${process.env.REACT_APP_API_PREFIX}/change-password`,
    "callback": "api/change-password",
    "access": "authenticated"
  },
  "forgot-password": {
    "url": `${process.env.REACT_APP_API_PREFIX}/forgot-password`,
    "callback": "api/forgot-password"
  },
  "confirm-forgot-password": {
    "url": `${process.env.REACT_APP_API_PREFIX}/confirm-forgot-password`,
    "callback": "api/confirm-forgot-password"
  },
  "init-file-upload": {
    "url": `${process.env.REACT_APP_API_PREFIX}/init-file-upload`,
    "callback": "api/init-file-upload"
  },
  "user-photo": {
    "url": `${process.env.REACT_APP_API_PREFIX}/user-photo/:userId/*`,
    "callback": "api/user-photo",
    "access": "authenticated"
  },
  "profile": {
    "url": `${process.env.REACT_APP_API_PREFIX}/profile`,
    "callback": "api/profile"
  },
  "user-exists": {
    "url": `${process.env.REACT_APP_API_PREFIX}/user-exists`,
    "callback": "api/user-exists"
  },
  "anonymous-job-applications": {
    "url": `${process.env.REACT_APP_API_PREFIX}/anonymous/job-applications`,
    "callback": "api/anonymous-job-applications"
  },
  "rtbf-send-verification": {
    "url": `${process.env.REACT_APP_API_PREFIX}/rtbf-send-verification`,
    "callback": "api/request-to-be-forgotten/send-verification"
  },
  "rtbf-code-verification": {
    "url": `${process.env.REACT_APP_API_PREFIX}/rtbf-code-verification`,
    "callback": "api/request-to-be-forgotten/verify-code"
  },
  "rtbf-deletion-submit": {
    "url": `${process.env.REACT_APP_API_PREFIX}/rtbf-deletion-submit`,
    "callback": "api/request-to-be-forgotten/submit-for-deletion"
  },
  "rtbf-authenticated-submit": {
    "url": `${process.env.REACT_APP_API_PREFIX}/rtbf-authenticated-submit`,
    "callback": "api/request-to-be-forgotten/authenticated-submit"
  },
  "user-migrate": {
    "url": `${process.env.REACT_APP_API_PREFIX}/user-migrate`,
    "callback": "api/user-migrate"
  },
  "anonymous-open-applications": {
    "url": `${process.env.REACT_APP_API_PREFIX}/anonymous/open-applications`,
    "callback": "api/anonymous-open-applications"
  },
  "generate-behat-captcha-cookie": {
    "url": `${process.env.REACT_APP_API_PREFIX}/generate-behat-recaptcha-cookie`,
    "callback": "api/generate-behat-captcha-cookie"
  },
  "verify-cognito-token": {
    "url": `${process.env.REACT_APP_API_PREFIX}/verify-cognito-token`,
    "callback": "api/verify-cognito-token"
  },
  "job-categories-list": {
    "url": `${process.env.REACT_APP_API_PREFIX}/job-categories-list`,
    "callback": "api/job-categories-list"
  },
  "branch-specialisms": {
    "url": `${process.env.REACT_APP_API_PREFIX}/branch-specialisms`,
    "callback": "api/branch-specialisms"
  },
  "get-recommended-jobs": {
    "url": `${process.env.REACT_APP_API_PREFIX}/get-recommended-jobs`,
    "callback": "api/get-recommended-jobs"
  },
  "get-configurations": {
    "url": `${process.env.REACT_APP_API_PREFIX}/get-configurations`,
    "callback": "api/get-configurations"
  },
  "application-status": {
    "url": `${process.env.REACT_APP_API_PREFIX}/application-status`,
    "callback": "api/application-status"
  },
  "application-statuses-list": {
    "url": `${process.env.REACT_APP_API_PREFIX}/application-statuses-list`,
    "callback": "api/application-statuses-list"
  },
  "dsar": {
    "url": `${process.env.REACT_APP_API_PREFIX}/dsar`,
    "callback": "api/dsar"
  },
  "specialisms-hierarchy": {
    "url": `${process.env.REACT_APP_API_PREFIX}/specialisms-hierarchy`,
    "callback": "api/specialisms-hierarchy"
  },
  "interview-settings": {
    "url": `${process.env.REACT_APP_API_PREFIX}/interview-settings`,
    "callback": "api/interview-settings"
  },
  "behat-all-masterlists": {
    "url": `${process.env.REACT_APP_API_PREFIX}/behat/AllMasterLists`,
    "callback": "api/behat/AllMasterLists"
  },
  "behat-all-specialism": {
    "url": `${process.env.REACT_APP_API_PREFIX}/behat/AllSpecialism`,
    "callback": "api/behat/AllSpecialism"
  },
  "user-delete": {
    "url": `${process.env.REACT_APP_API_PREFIX}/user-delete`,
    "callback": "api/user-delete"
  },
  "get-env-variables": {
    "url": `${process.env.REACT_APP_API_PREFIX}/qa-get-environment-variables`,
    "callback": "api/get-environment-variables"
  },
  "get-login-page-settings": {
    "url": `${process.env.REACT_APP_API_PREFIX}/get-login-page-settings`,
    "callback": "api/get-login-page-settings"
  }
};
